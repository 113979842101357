<template>
  <v-container>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        color="primary"
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-form ref="form" v-model="valid" :lazy-validation="lazy" :disabled="loading">
      <v-row>
        <v-col>
          <h3>Information</h3>

          <v-text-field
            v-model="custitem_requestor"
            :rules="emailRules"
            label="Requested By (email) *"
            :disabled="loading"
            required
          ></v-text-field>

          <v-autocomplete
            v-model="parent"
            :items="payloads.payloadA"
            :loading="loading"
            label="Subitem Of *"
            :disabled="loading"
            required
          ></v-autocomplete>

          <v-switch v-model="serialised" class="ma-2" :disabled="loading" label="Serialised"></v-switch>

          <v-textarea
            outlined
            name="item_description"
            v-model="item_description"
            :disabled="loading"
            label="Item Description *"
            required
          ></v-textarea>

          <v-textarea
            outlined
            name="french_description"
            v-model="french_description"
            :disabled="loading"
            label="French Description"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="class_"
            :items="payloads.payloadB"
            :loading="loading"
            name="class"
            label="Brand *"
            :disabled="loading"
            required
          ></v-autocomplete>

          <v-autocomplete
            v-model="custitem_available_market"
            :items="payloads.payloadE"
            :loading="loading"
            name="custitem_available_market"
            label="Available Market *"
            :disabled="loading"
            required
          ></v-autocomplete>

          <v-autocomplete
            v-model="custitem_pos_type"
            name="custitem_pos_type"
            :items="payloads.payloadF"
            :loading="loading"
            label="POS Unit Type"
            :disabled="loading"
          ></v-autocomplete>

          <v-text-field
            v-model="custitem_color"
            name="custitem_color"
            label="Colour"
            :disabled="loading"
          ></v-text-field>

          <v-text-field
            v-model="custitem_size"
            name="custitem_size"
            label="Size"
            :disabled="loading"
          ></v-text-field>

          <v-autocomplete
            v-model="custitem_replenishment_type"
            :items="payloads.payloadG"
            :loading="loading"
            label="Replenishment Type *"
            :disabled="loading"
            required
          ></v-autocomplete>

          <v-text-field
            label="Production Lead Time (Calendar Days)"
            :disabled="loading"
            name="custitemprodleadtime"
            v-model="custitemprodleadtime"
            suffix="Days"
          ></v-text-field>

          <v-text-field label="MOQ" name="custitem2" v-model="custitem2" value :disabled="loading" type="number"></v-text-field>

          <v-text-field
            v-model="custitem_pao"
            name="custitem_pao"
            label="Period After Opening"
            :disabled="loading"
            type="number"
          ></v-text-field>

          <v-autocomplete
            v-model="countryofmanufacture"
            :items="payloads.payloadH"
            :loading="loading"
            name="countryofmanufacture"
            label="Manufacturer Country"
            :disabled="loading"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>

        <v-col>
          <h3>Vendor</h3>
          <v-autocomplete
            v-model="vendor"
            :items="payloads.payloadC"
            :loading="loading"
            name="vendor"
            label="Vendor"
            :disabled="loading"
          ></v-autocomplete>

          <v-text-field
            label="Purchase Price *"
            :disabled="loading"
            name="purchaseprice"
            v-model="purchaseprice"
            value
            suffix="$"
            required
          ></v-text-field>

          <v-autocomplete
            v-model="vendorcurrencyname"
            :items="currency"
            :loading="loading"
            name="vendorcurrencyname"
            label="Purchase Currency *"
            :disabled="loading"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>

        <v-col>
          <h4>
            Components
            <v-btn v-if="showComponents" class="mx-2" fab dark small color="primary" v-on:click="componentCount += 1">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </h4>
          <h5 v-if="!showComponents">Components Unavailable</h5>

          <template v-if="showComponents">
            <div v-for="(index,componentCount) in componentCount" :key="componentCount">
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="item[index-1]"
                    :items="memberItems"
                    :loading="loading"
                    name="item"
                    label="Member Item *"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Member Quantity *"
                    name="quantity"
                    v-model="quantity[index-1]"
                    required
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn class="mx-2" fab dark small color="primary" v-on:click="removeRow(index-1)">
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
      </v-row>

      <template>
        <vue-recaptcha sitekey="6LdSZ3oUAAAAABf0KWM3UB9wkjB18h3ExgsGY0T9" :loadRecaptchaScript="true"></vue-recaptcha>
      </template>

      <v-btn color="error" class="mr-4" @click="resetForm" :disabled="loading">Reset Form</v-btn>

      <v-btn color="success" :disabled="loading" v-on:click="submitForm">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from "axios";
import qs from "qs";

export default {
  components: { VueRecaptcha },
  created() {
    this.loading = true;
    



    axios
      .get(`${this.url}`)
      .then(response => {
        // JSON responses are automatically parsed.
        console.log(response.data)

        this.payloads = response.data.return
        this.memberItems = this.payloads.payloadI
        this.loading = false;

      })
      .catch(e => {
        console.log(e)
      });
  },
  data: () => ({
    url: `${document.URL}/index.php`,
    valid: true,
    loading: true,


    snackbar: {
      show: false,
      text: '',
      timeout: 20000,
      color: "success",
    },


    payloads: {
      payloadA: {},
      payloadB: {},
      payloadC: {},
      payloadD: {},
      payloadE: {},
      payloadF: {},
      payloadG: {},
      payloadH: {},
      payloadI: {},
      payloadJ: {},
      currency: {},
      component_types: {},
      accounts: {}
    },

    componentCount: 1,

    showComponents: true,
    currency: { value: null, text: "Please select vendor..." },
    serialised: false,
    submitType: "",
    assetaccount_internalid: null,
    expenseaccount_internalid: null,
    currencyid: null,
    currencyname: null,
    vendorcurrencyname: null,
    memberItems: {},

    item: [],
    quantity: [],
    customform: "",
    externalid: "",
    itemid: "",
    parent: "",
    upccode: "",
    custitem_inner_tun_code: "",
    custitem_outer_tun_code: "",
    item_description: "",
    french_description: "",
    assetaccount: "",
    cogsaccount: "",
    subsidiary: "",
    includechildren: "",
    isinactive: "",
    unitstype: "",
    costingmethod: "",
    costingmethoddisplay: "",
    tracklandedcost: "",
    taxschedule: "",
    custitemfrench_display: "",
    class_: "",
    custitem_available_market: "",
    custitem_pos_type: "",
    custitem_color: "",
    custitem_size: "",
    custitem_replenishment_type: "",
    custitemprodleadtime: "",
    custitem2: "",
    custitem_pao: "",
    countryofmanufacture: "",
    custitem_length: "",
    custitem_width: "",
    custitem_height: "",
    custitem_weight: "",
    custitem_inner_quantity: "",
    custitem_inner_length: "",
    custitem_inner_width: "",
    custitem_inner_height: "",
    custitem_inner_weight: "",
    custitem_outer_quantity: "",
    custitem_outer_length: "",
    custitem_outer_width: "",
    custitem_outer_height: "",
    custitem_outer_weight: "",
    custitem_pallet_no_of_outers: "",
    custitem_pallet_no_of_outers_per_layer: "",
    custitem_pallet_layers_per_pallet: "",
    custitem_total_stock_height: "",
    custitem_pallet_total_pallet_height: "",
    custitem_requestor: "",
    vendor: "",

    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],

  }),
  watch: {
    vendor(val) {
      console.log("vendor", val)
      this.updateCurrency(val)
    },
    parent(val) {
      console.log("parent", val)
      this.showHideComponents(val)
      this.assetaccount_internalid = this.payloads.account[val].assetaccount
      this.expenseaccount_internalid = this.payloads.account[val].expenseaccount
    },
    vendorcurrencyname(val) {
      console.log("vendorcurrencyname", val)
      this.currencyid = val
      this.currencyname = this.payloads.currency[val].text
    },
    serialised(val) {
      console.log("serialised", val)
      if (val) {
        this.memberItems = this.payloads.payloadJ
      } else {
        this.memberItems = this.payloads.payloadI
      }
    }
  },
  methods: {
    // send your recaptcha token to the server to verify it
    submit (response) {
      console.log(response)
    },
    // execute the recaptcha widget
    executeRecaptcha () {
      this.$refs.recaptcha.execute()
    },
    removeRow: function(index) {
      this.componentCount -= 1
      this.$delete(this.item, index)
      this.$delete(this.quantity, index)
    },
    updateCurrency: function(id) {
      this.currency = this.payloads.currency[id];
    },
    showHideComponents: function(id) {
      this.submitType = this.payloads.component_types[id]

      if (this.payloads.component_types[id] == "inventoryitem") {
        this.showComponents = false;
      } else {
        this.showComponents = true;
      }
    },
    submitForm: function() {
      this.loading = true;

      var date = new Date()
      var dateString =  '' + date.getFullYear() + (date.getMonth()+1) + date.getDay() + date.getHours() + date.getMinutes() + date.getSeconds()
      var theType = this.submitType

      if (this.serialised) {
        switch (this.submitType) {
          case 'inventoryitem':
              theType = 'serialized' + this.submitType
          break;

          case 'assemblyitem':
              theType = 'serialized' + this.submitType
          break;
        }
      }

      let  data = {
            "type": theType,
            "action":"create",
            "header":{
                "customform": this.customform,
                "externalid": "NEWITEM" + dateString,
                "itemid": "SUPPLYC_TO_UPDATE" + dateString,
                "parent": this.parent,
                "upccode": "SUPPLYC_TO_UPDATE" + dateString,
                "custitem_inner_tun_code": "SUPPLYC_TO_UPDATE" + dateString,
                "custitem_outer_tun_code": "SUPPLYC_TO_UPDATE" + dateString,
                "displayname": this.item_description.replace( /[\r\n]+/gm, "" ),
                "description": this.item_description,
                "salesdescription": this.item_description,
                "purchasedescription": this.item_description,
                "assetaccount": this.assetaccount_internalid,
                "cogsaccount": this.expenseaccount_internalid,
                "subsidiary": 3,
                "includechildren": "T",
                "isinactive": "T",
                "unitstype": 1,
                "costingmethod":  this.serialised ? "LIFO" : "AVG",
                "costingmethoddisplay": this.serialised ? "Specific" : "Average",
                "tracklandedcost": "T",
                "taxschedule": 1,
                "custitemfrench_display": this.french_description,
                "class": this.class_,
                "custitem_available_market": this.custitem_available_market,
                "custitem_pos_type": this.custitem_pos_type,
                "custitem_color": this.custitem_color,
                "custitem_size": this.custitem_size,
                "custitem_replenishment_type": this.custitem_replenishment_type,
                "custitemprodleadtime": this.custitemprodleadtime,
                "custitem2": this.custitem2,
                "custitem_pao": this.custitem_pao,
                "countryofmanufacture": this.countryofmanufacture,
                "custitem_length": this.custitem_length,
                "custitem_width": this.custitem_width,
                "custitem_height": this.custitem_height,
                "weight": this.custitem_weight,
                "custitem_inner_quantity": this.custitem_inner_quantity,
                "custitem_inner_length": this.custitem_inner_length,
                "custitem_inner_width": this.custitem_inner_width,
                "custitem_inner_height": this.custitem_inner_height,
                "custitem_inner_weight": this.custitem_inner_weight,
                "custitem_outer_quantity": this.custitem_outer_quantity,
                "custitem_outer_length": this.custitem_outer_length,
                "custitem_outer_width": this.custitem_outer_width,
                "custitem_outer_height": this.custitem_outer_height,
                "custitem_outer_weight": this.custitem_outer_weight,
                "custitem_pallet_no_of_outers": this.custitem_pallet_no_of_outers,
                "custitem_pallet_no_of_outers_per_layer": this.custitem_pallet_no_of_outers_per_layer,
                "custitem_pallet_layers_per_pallet": this.custitem_pallet_layers_per_pallet,
                "custitem_total_stock_height": this.custitem_total_stock_height,
                "custitem_pallet_total_pallet_height": this.custitem_pallet_total_pallet_height,
                "custitem_requestor": this.custitem_requestor,
            },
        }

        if (this.currencyid !== null) {
          if (!data["sublists"]) {
            data.sublists = []
          }
          data.sublists.push({ "itemvendor": {
                            "vendor": this.currencyid,
                            "vendorcurrencyname": this.currencyname,
                            "purchaseprice": this.purchaseprice
                            }
                          })
        }

        if (this.item.length > 0 && this.quantity.length > 0) {
          if (!data["sublists"]) {
            data.sublists = []
          }

          console.log(data)

            data.sublists.push({"member": []})

          let tempIndex = data.sublists.length-1;

          for (let i = 0; i <= this.item.length-1;i++) {
             data.sublists[tempIndex]['member'] = data.sublists[tempIndex]['member'].concat({"item": this.item[i], 'quantity': this.quantity[i]})
          }
        }

        console.log(data)

        this.snackbar.show = false
        axios
          .post(`${this.url}/?type=post`,qs.stringify(data))
          .then(response => {
            // JSON responses are automatically parsed.
            console.log(response.data);

            if(response.data.response.status === 'success') {
              this.snackbar.text = `Created successfully with internalid ${response.data.response.intnernalid}`
              this.snackbar.color = 'success'
              this.snackbar.show = true
              this.resetForm()
            } else {
              this.snackbar.text = response.data.response.message
              this.snackbar.color = 'error'
              this.snackbar.show = true
            }

            this.loading = false;
          })
          .catch(e => {

            this.snackbar.text = JSON.stringify(e)
            this.snackbar.color = 'error'
            this.snackbar.show = true
            
            this.loading = false;
            console.log(e);
          })

          
          


    },
    resetForm: function() {
        this.componentCount = 1

        this.showComponents = true
        this.currency = { value: null, text: "Please select vendor..." }
        this.serialised = false
        this.submitType = ""
        this.assetaccount_internalid = null
        this.expenseaccount_internalid = null
        this.currencyid = null
        this.currencyname = null
        this.vendorcurrencyname = null

        this.item = []
        this.quantity = []
        this.customform = ""
        this.externalid = ""
        this.itemid = ""
        this.parent = ""
        this.upccode = ""
        this.custitem_inner_tun_code = ""
        this.custitem_outer_tun_code = ""
        this.item_description = ""
        this.french_description = ""
        this.assetaccount = ""
        this.cogsaccount = ""
        this.subsidiary = ""
        this.includechildren = ""
        this.isinactive = ""
        this.unitstype = ""
        this.costingmethod = ""
        this.costingmethoddisplay = ""
        this.tracklandedcost = ""
        this.taxschedule = ""
        this.custitemfrench_display = ""
        this.class_ = ""
        this.custitem_available_market = ""
        this.custitem_pos_type = ""
        this.custitem_color = ""
        this.custitem_size = ""
        this.custitem_replenishment_type = ""
        this.custitemprodleadtime = ""
        this.custitem2 = ""
        this.custitem_pao = ""
        this.countryofmanufacture = ""
        this.custitem_length = ""
        this.custitem_width = ""
        this.custitem_height = ""
        this.custitem_weight = ""
        this.custitem_inner_quantity = ""
        this.custitem_inner_length = ""
        this.custitem_inner_width = ""
        this.custitem_inner_height = ""
        this.custitem_inner_weight = ""
        this.custitem_outer_quantity = ""
        this.custitem_outer_length = ""
        this.custitem_outer_width = ""
        this.custitem_outer_height = ""
        this.custitem_outer_weight = ""
        this.custitem_pallet_no_of_outers = ""
        this.custitem_pallet_no_of_outers_per_layer = ""
        this.custitem_pallet_layers_per_pallet = ""
        this.custitem_total_stock_height = ""
        this.custitem_pallet_total_pallet_height = ""
        this.custitem_requestor = ""
        this.vendor = ""
    }
  }
};
</script>
