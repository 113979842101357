<template>
  <v-app>
    <v-content>           
        <v-row align="center" justify="center">
          <img class="text-center" src="./assets/logo.svg" style="width: 275px;">
        </v-row>
        <h1 class="text-center">New Item Request Form</h1>
      <NbnForm />
    </v-content>
  </v-app>
</template>

<style>
    .theme--light.v-application {
        font-family: "Lato", "Helvetica Neue", "Helvetica", Arial, sans-serif;
        font-weight: 300;
        background-color: rgba(245, 238, 234, 0.8) !important;
        padding: 25px 0;
        color: #392f2b;
    }
</style>


<script>
import NbnForm from "./components/NbnForm";

export default {
  name: "App",

  components: {
    NbnForm
  },
  created() {
    document.title = 'New Item Request Form'
  },
  data: () => ({
    //
  })
};
</script>
